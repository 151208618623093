import React, { useState, useEffect } from "react";
import useCart from "../state";
//import { AiFillCloseCircle } from "react-icons/ai";
import { AiFillPlusCircle } from "react-icons/ai";
import { AiFillMinusCircle } from "react-icons/ai";
import axios from "axios";
import { motion } from "framer-motion";
import { isMobile, isTablet } from "react-device-detect";

function Cart() {

  const open = useCart((state) => state.open);
  const items = useCart((state) => state.items);
  const [itemsIds, setItemsIds] = useState([]);
  const [total, setTotal] = useState(0);
  const calculateTotalPrice = () => {
    let totalPrice = 0;
    items.forEach((item) => {
      totalPrice += parseFloat(item.Price) * item.quantity;
    });
    return totalPrice;
  };
  const addItemQuantity = useCart((state) => state.addItemQuantity);
  const removeItemQuantity = useCart((state) => state.removeItemQuantity);

  useEffect(() => {
    const newItemsIds = items.map((item) => ({
      key: item.id,
      price: item.Stripe_id,
      quantity: item.quantity,
      total: parseFloat(item.Price) * item.quantity,
    }));
    setItemsIds(newItemsIds);
  }, [items]);

  useEffect(() => {
    if (items.length === 0) {
      useCart.getState().setOpen(false);
    }
  }, [items]);

  useEffect(() => {
    setTotal(items.reduce((acc, item) => acc + item.quantity, 0));
  }, [items]);

  const handleCheckout = () => {
    axios
      .post(
        "https://surroundproback-production.up.railway.app/checkout",
        itemsIds
      )
      .then((response) => {
        const url = response.data.url;
        window.location.href = url;
      })
      .catch((error) => {
        console.error(error);
      });
  };


  return (
    <>
      <motion.div
        variants={{
          open: {
            opacity: 1,
            y: 20,
            x: isTablet
              ? "calc(100vw - 450px)"
              : isMobile
                ? "calc(100vw - 350px)"
                : "calc(100vw - 450px)",
          },
          closed: {
            opacity: 0,
            x: "100vw",
            y: 20,
          },
        }}
        initial="closed"
        animate={open ? "open" : "closed"}
        className="grid grid-rows-12 py-6 overflow-y-scroll w-[300px] justify-center fixed bg-white max-h-[80%] rounded-2xl my-6 shadow-2xl shadow-black top-20 z-50"
      >
        <div className="text-center mb-6 font-akzidenz-medium">
          Cart: {total}
        </div>
        <div>
          {items.map((item) => (
            <motion.div
              key={item.id}
              initial={{ x: 200 }}
              animate={{ x: 0 }}
              className="flex flex-col gap-4 py-10 items-center justify-between mb-5 bg-Shark p-4 rounded-xl text-white font-akzidenz-light w-[250px]"
            >
              <div className="flex flex-col justify-center items-center">
                <div
                  className="h-10 w-10 bg-white bg-cover bg-no-repeat bg-center border-black border rounded-full"
                  style={{ backgroundImage: `url(${item.Image})` }}
                ></div>
                <div className="text-center px-2 text-WaterLeaf">
                  {item.Model}
                </div>
              </div>

              <div className="flex flex-row justify-center items-center gap-2">
                <div onClick={() => removeItemQuantity(item)}>
                  <AiFillMinusCircle size={20} />
                </div>
                <div>{item.quantity}</div>
                <div onClick={() => addItemQuantity(item)}>
                  <AiFillPlusCircle size={20} />{" "}
                </div>
              </div>
              <div className="text-center">
                {item.Price.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
            </motion.div>
          ))}
          <div className="text-center font-akzidenz-medium">
            Total Price: ${calculateTotalPrice().toLocaleString()}
          </div>
          {items.length > 0 && (
            <button
              className="bg-Shark text-WaterLeaf rounded-xl w-full h-16 my-4 checkout-button hover:-translate-y-1 hover:shadow-md hover:shadow-gray-700 transition duration-500 ease-in-out"
              onClick={handleCheckout}
            >
              CHECKOUT
            </button>
          )}
        </div>



      </motion.div>
    </>
  );
}

export default Cart;
